import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ColumnDefinition, ReactTabulator } from "react-tabulator";
import { useEffect, useState } from "react";
import ButtonNeoGen from "./button-neogen";
import PrintPre from "./print-pre";
import React from "react";
import AddColumn from "./modals/add-column";

export default function CustomTable() {
    const [columns, setColumns] = useState<ColumnDefinition[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [numColumns, setNumColumns] = useState(1);
    const [numRows, setNumRows] = useState(1);
    const [showAddColumn, setShowAddColumn] = useState(false);
    let ref = React.useRef<any>();
    // function updateData() {
    //     // setColumns(
    //     //     Array.from(Array(numColumns).keys()).map((i) => {
    //     //         return { title: `col${i}`, field: `col${i}`, editor: "input" };
    //     //     }),
    //     // );
    //     setData(
    //         Array.from(Array(numRows).keys()).map((i) => {
    //             const row = { id: i, bla: 1 };
    //             Array.from(Array(numColumns).keys()).forEach((j) => {
    //                 row[`col${j}` as keyof typeof row] = `col${j} row${i}` as any;
    //             });
    //             return row;
    //         }),
    //     );
    // }

    // useEffect(() => {
    //     console.log(numColumns, numRows);
    //     updateData();
    // }, [numColumns, numRows]);

    const addColumn = (name: string) => {
        // setNumColumns((c) => c + 1);
        setShowAddColumn(false);
        setColumns((c) => {
            return [
                ...c,
                {
                    title: name,
                    field: name,
                    editor: "input",
                },
            ];
        });
        // Go through the existing data and add the new column to each row
        setData((d) => {
            return d.map((row) => {
                return { ...row, [name]: "Empty" };
            });
        });
    };

    return (
        <div>
            <ButtonNeoGen
                label="Add Column"
                onClick={() => {
                    // setNumColumns((c) => c + 1);
                    setShowAddColumn(true);
                }}
            >
                Add Column
            </ButtonNeoGen>
            <ButtonNeoGen
                label="Add Row"
                onClick={() => {
                    // setNumRows((c) => c + 1);
                    setData((d) => {
                        return [...d, { id: d.length }];
                    });
                }}
            >
                Add Row
            </ButtonNeoGen>
            <ButtonNeoGen
                onClick={() => {
                    console.log((ref as any).current.options.data);
                    alert(JSON.stringify((ref as any).current.options.data));
                }}
            >
                Get Data
            </ButtonNeoGen>
            <ReactTabulator
                onRef={(r) => (ref = r)}
                data={data}
                columns={columns}
                options={{ data: data, reactiveData: true }}
            />
            <div></div>
            <AddColumn show={showAddColumn} close={() => setShowAddColumn(false)} save={(name) => addColumn(name)} />
        </div>
    );
}
