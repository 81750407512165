import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ColumnDefinition, ReactTabulator } from "react-tabulator";
import { useEffect, useState } from "react";
import ButtonNeoGen from "../../../layout/button-neogen";
import PrintPre from "../../../layout/print-pre";
import React from "react";
import CustomTable from "../../../layout/custom-table";

export default function Tables() {
    return (
        <div>
            <CustomTable />
        </div>
    );
}
